/* // NOTE: the order of these styles DO matter */

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer
}

.DateInput {
  margin: 0;
  padding: 0;
  background: #f5f5f5;
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  width: calc(22px + 10vw);
  vertical-align: middle;
}

.DateInput_input {
  font-weight: 500;
  font-size: calc(5px + 0.9vw);
  line-height: calc(0.5vw + 0.5vh);
  color: #00313D;
  background-color: #f5f5f5;
  width: 100%;
  padding: 0px 0px 0px;
  margin-left: calc(0px + 1.2vw);
  margin-right: calc(0px + 0.6vw);
  white-space: nowrap;
  overflow-x: hidden;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
  text-align: left;
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 0px solid #f5f5f5;
}

.DateInput_input__focused {
  outline: 0;
  color: #00313D;
  background: #f5f5f5;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #00313D;
  border-left: 0
}

.DateRangePickerInput {
  background-color: #f5f5f5;
  display: inline-block;
  align-items: center;
}

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #f5f5f5;
  width: 0;
  height: 0;
}

.CalendarDay__highlighted_calendar {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #a6aaab;
  border: 1px double #a6aaab;
  color: #82888a;
}